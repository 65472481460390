import React, { useState } from 'react'
import classNames from 'classnames'
import { setCookie } from 'cookies-next'
import { useRouter } from 'next/router'
import { When } from 'react-if'
import { Button, LinkButton } from '@/atoms/Button'
import { Input } from '@/atoms/Input'
import { AsLabel, CaptionXS, LabelLG } from '@/atoms/Text'
import { paths } from '@/constants'
import { LOGIN_STARTED_ORIGIN } from '@/constants/cookies'
import { useThemeContext } from '@/contexts/ThemeContext'
import { useCaptureOrderEmail } from '@/services/EmailCaptureService'
import { isValidEmail } from '@/utils/StripeFormValidator'
import { useSafeTrack } from '@/utils/analytics'
import { buildLoginUrl } from '@/utils/auth-utils'
import { reportErrorToBugsnag } from '@/utils/bugsnag'
import { logger } from '@/utils/logging'
import { Translate, useTranslate } from '@/utils/translate/translate-client'
import { getAttribution } from '@/views/GuildStripeCheckoutSessionView/GuildEmbeddedStripeCheckoutSession'

interface GuildEmailInputProps {
  isHero?: boolean
  hasUser?: boolean
  isCentered?: boolean
  loginOrigin?: 'home'
  variant?: 'left-white' | 'centered-oxide'
  sideBySide?: boolean
  isHome?: boolean
}

export const GuildEmailInput: React.FC<GuildEmailInputProps> = ({
  hasUser = false,
  isHero = true,
  isCentered = true,
  loginOrigin,
  variant = 'centered-oxide',
  sideBySide = false,
  isHome = false,
}) => {
  const { t } = useTranslate('guild')
  const track = useSafeTrack()
  const { isDarkMode } = useThemeContext()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')
  const router = useRouter()
  const captureOrderEmail = useCaptureOrderEmail()
  const checkIfUserExists = async (
    event: React.MouseEvent<HTMLButtonElement> | React.KeyboardEvent<HTMLInputElement>,
  ) => {
    setError('')
    if (hasUser) {
      if (loginOrigin) {
        setCookie(LOGIN_STARTED_ORIGIN, loginOrigin)
      }
      router.push(paths.guild.pricing)
      return
    }

    if (!isValidEmail(email)) {
      setError(t('invalidEmail', 'Please enter a valid email address.'))
      return
    }
    event.preventDefault()

    const { projectSlugs } = getAttribution()
    captureOrderEmail({
      position: 'guild-auth-flow',
      subscriptionGroupId: 'b9fc9a1a-62da-4537-9693-c0f7e9690128',
      email: email,
      projectSlug: projectSlugs ?? '',
    })
      .then(() => {
        track('Guild Signup Email Submitted', { email })
      })
      .catch((e) => {
        logger().error('An error occurred while attempting to capture order email', e)
        reportErrorToBugsnag(e instanceof Error ? e : new Error(JSON.stringify(e)))
        track('Guild Signup Email Submission Error', { email })
      })

    const userCheck = await fetch(`/api/authByApi/checkUser?email=${encodeURIComponent(email)}`)
    const userCheckJson = await userCheck.json()
    const ellisOauthUrl = buildLoginUrl(paths.guild.pricing) + `&email=${encodeURIComponent(email)}`

    if (userCheckJson.exists) {
      router.push(ellisOauthUrl)
      return
    } else {
      if (loginOrigin) {
        setCookie(LOGIN_STARTED_ORIGIN, loginOrigin)
      }
      router.push(`${paths.guild.signup.confirm}?email=` + encodeURIComponent(email))
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      checkIfUserExists(event)
    }
  }

  const isLeftWhite = variant === 'left-white'
  const isCenteredOxide = variant === 'centered-oxide'

  return (
    <div onKeyDown={handleKeyDown} className="w-full">
      <When condition={!hasUser}>
        <div
          className={classNames(
            'flex w-full items-center',
            sideBySide ? 'flex-row gap-2' : 'flex-col gap-2 se:-mt-4',
            isHero ? 'lg:flex-row lg:gap-2' : 'lg:gap-8 mt-8 md:mt-0 lg:mt-8',
            {
              'justify-center': isCentered,
              'md:items-start mt-6 se:mt-0 md:mb-6': isLeftWhite,
            },
          )}
        >
          <div className={classNames('lg:w-3/5', sideBySide ? 'grow-1' : 'w-full', { 'max-w-[326px]': isLeftWhite })}>
            <Input
              label=""
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
              placeholder={t('email', 'Email Address')}
              containerClassName={classNames('!mt-0', sideBySide ? '' : 'px-4 lg:px-0', { 'md:px-0': isLeftWhite })}
              inputClasses={classNames(
                'photon-paragraph-md min-h-[56px] se:min-h-[48px] border border-b rounded-lg p-2 w-full !duration-[0ms] bg-transparent/35 focus-visible:ring-transparent focus-visible:ring-0 focus-visible:ring-offset-0 focus-visible:ring-offset-transparent disabled:bg-white',
                isDarkMode && isHero ? 'bg-transparent/35 text-white' : 'bg-white text-black max-w-[450px]',
                {
                  '!min-h-[48px] lg:!min-h-[56px] max-w-[326px] !bg-core-gray-900 !text-core-gray-400 border-core-gray-800 border-[1.5px] border-b-[1.5px] placeholder-core-gray-400':
                    isLeftWhite,
                },
              )}
              labelClassName="photon-label-sm font-whitney font-medium"
            />
            {error && (
              <div className="h-4">
                <CaptionXS role="alert" className="mx-auto">
                  <span className="text-error-500">{error}</span>
                </CaptionXS>
              </div>
            )}
          </div>
          <When condition={isLeftWhite}>
            <div className="mb-12 flex w-full flex-row justify-center md:justify-start lg:mb-0 lg:w-auto se:mb-4">
              <Button
                onClick={checkIfUserExists}
                variant="white"
                className={classNames(
                  'btn mb-4 my-0 rounded-lg py-2 min-h-[40px] lg:min-h-[56px] min-w-[142px] lg:px-0',
                  {
                    'lg:w-fit': !isHero,
                    'lg:w-2/5': isHero,
                  },
                )}
              >
                <LabelLG
                  as={AsLabel}
                  color="core-gray-950"
                  weight="semibold"
                  className="se:photon-label-md flex h-full cursor-pointer flex-row items-center justify-center gap-1"
                >
                  <Translate i18nKey="getStarted" t={t}>
                    Get Started
                  </Translate>
                </LabelLG>
              </Button>
              {!isHome && (
                <LinkButton
                  href={paths.guild.join}
                  variant="white"
                  outline
                  className={classNames(
                    'flex justify-center px-3 mb-4 py-[11px] w-full sm:w-auto border-0 hover:!bg-transparent max-w-[115px] max-h-14 min-h-[40px] lg:min-h-[56px] hover:text-core-gray-400',
                  )}
                  onClick={() => track('Home Watch Clicked')}
                >
                  {t('learnMore', 'Learn More')}
                </LinkButton>
              )}
            </div>
          </When>
          <When condition={isCenteredOxide}>
            <Button
              onClick={checkIfUserExists}
              variant={isHero ? 'oxide' : 'core-gray-900'}
              className={classNames('btn md:mb-0 my-0 rounded-lg py-2 min-h-[56px] se:min-h-[48px] min-w-[142px]', {
                'lg:w-fit': !isHero,
                'lg:w-2/5': isHero,
                'mb-4': !sideBySide,
              })}
            >
              <LabelLG
                as={AsLabel}
                color="white"
                weight="semibold"
                className="se:photon-label-md flex h-full cursor-pointer flex-row items-center justify-center gap-1"
              >
                <Translate i18nKey="getStarted" t={t}>
                  Get Started
                </Translate>
              </LabelLG>
            </Button>
          </When>
        </div>
      </When>
      <When condition={hasUser}>
        <div
          className={classNames(
            'flex w-full flex-col gap-8 se:-mt-4 se:gap-6 items-center',
            isHero ? 'lg:flex-row lg:gap-2' : 'lg:gap-8 mt-8',
            {
              'justify-center': isCentered,
            },
          )}
        >
          <Button
            onClick={checkIfUserExists}
            variant={isHero ? 'oxide' : 'core-gray-900'}
            className={classNames('btn mb-4 my-0 lg:w-2/5 rounded-lg py-3 min-h-[56px]', {
              'lg:w-fit': !isHero,
              'lg:w-2/5': isHero,
              'lg:mx-auto': isCentered,
            })}
          >
            <LabelLG
              as={AsLabel}
              color="white"
              weight="semibold"
              className="se:photon-label-md flex h-full cursor-pointer flex-row items-center justify-center gap-1"
            >
              <Translate i18nKey="getStarted" t={t}>
                Get Started
              </Translate>
            </LabelLG>
          </Button>
        </div>
      </When>
    </div>
  )
}
