import { useCallback, useEffect, useMemo, useState } from 'react'
import segment from '@analytics/segment'
import {
  identify as fullstoryIdentify,
  init as fullstoryInit,
  isInitialized as isFullstoryInitialized,
} from '@fullstory/browser'
import { default as AnalyticsModule } from 'analytics'
import { getCookie } from 'cookies-next'
import isEmpty from 'lodash/isEmpty'
import { NextRouter, useRouter } from 'next/router'
import { isAndroid, isDesktop, isIOS, isMobile } from 'react-device-detect'
import { AnalyticsProvider } from 'use-analytics'
import { paths } from '@/constants'
import { SEGMENT_ANONYMOUS_ID_COOKIE } from '@/constants/cookies'
import { latamCountries } from '@/constants/latamCountries'
import { ExperimentContextProvider } from '@/experimentation/ExperimentContextProvider'
import { getGrowthbook } from '@/experimentation/utils'
import { useBranch } from '@/layout/useBranch'
import { useHasVPPAConsent } from '@/services/EllisIslandService/legalDocumentHooks'
import { proxyServiceURLOrigin } from '@/services/Partytown'
import { useUser } from '@/services/UserService'
import { ReactFCC } from '@/types/react'
import { getRID } from '@/utils/RIDUtil'
import { ECOMMERCE_EVENTS, EcommPayload } from '@/utils/analytics/ecommerce-events'
import { getItemOrFirstEntry } from '@/utils/array-utils'
import {
  getBooleanFromSessionStorage,
  getObjectFromSessionStorage,
  writeToSessionStorage,
} from '@/utils/session-storage'
import { getIsAngelEmployeeUser, getUserDisplayName } from '@/utils/users'
import { angelPartytownPlugin } from './plugins/angel-partytown'
import { everflowSegmentPlugin } from './plugins/everflow'

const appName = 'angel-web'

const buildId = process.env.NEXT_PUBLIC_BUILD_ID as string

// Segment user is always set in middleware with a cookie, so we need to sync it on init of David Wells analytics library.
// Setting syncAnonymousId to true is meant to sync the anonymousId across both segment and David's library, BUT it's not working
// on first page load (David lib and Segment have two different anonymous ID's). This custom plugin
// intercepts and overrides the anonymous ID as suggested here: https://github.com/DavidWells/analytics/issues/258
const customAnonIdPlugin = {
  name: 'set-anon-id',
  bootstrap: () => {
    /**
     * in addition to the setItemStart, we need to make sure to keep the localStorage that Segment uses in sync
     * with the cookie. We need the cookie for server side and middleware experiments.
     * We want to use the cookie value because it gets cleared and refreshed when a user logs out
     * which is the desired behavior because we don't know if it's the same person anymore.
     * so, if the cookie doesn't match localstorage when we start up, we should remove the localstorage entry.
     * i tried setting the localstorage entry instead, but that did not have the desired effect.
     * by removing it though, it does get set correctly from the setItemStart method in the same request/page load
     */
    if (typeof localStorage !== 'undefined') {
      const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)

      if (typeof anonId === 'string') {
        const localAnonId = localStorage.getItem('__anon_id')
        const localAjsAnonymousId = localStorage.getItem(SEGMENT_ANONYMOUS_ID_COOKIE)

        if (
          (localAnonId && JSON.parse(localAnonId) !== anonId) ||
          (localAjsAnonymousId && JSON.parse(localAjsAnonymousId) !== anonId)
        ) {
          localStorage.removeItem('__anon_id')
          localStorage.removeItem('__user_id')
          localStorage.removeItem('__user_traits')
          localStorage.removeItem('ajs_anonymous_id')
          localStorage.removeItem('ajs_user_id')
          localStorage.removeItem('ajs_user_traits')
        }
      }
    }
  },
  setItemStart: ({ payload }: { payload: { key: string } }) => {
    if (payload.key === '__anon_id' || payload.key === 'ajs_anonymous_id') {
      const anonId = getCookie(SEGMENT_ANONYMOUS_ID_COOKIE)
      if (anonId) {
        return {
          ...payload,
          ...{ value: anonId },
        }
      }
    }
  },
}

const segmentPlugin = segment({
  syncAnonymousId: true,
  writeKey: process.env.NEXT_PUBLIC_SEGMENT_KEY,
  // eslint-disable-next-line prettier/prettier
  customScriptSrc: `${proxyServiceURLOrigin}/segment/analytics.js/v1/${process.env.NEXT_PUBLIC_SEGMENT_KEY}/analytics.min.js`,
})

const theatricalAngelPartytownPlugin = angelPartytownPlugin({
  writeKey: process.env.NEXT_PUBLIC_THEATRICAL_SEGMENT_KEY,
  enabledDefault: false,
  enableRegex: /^https?:\/\/[-.a-zA-Z]+(:\d+)?\/((tickets)|(movies)|(pre-sales))\/?/,
})

const SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS = ['homestead']

export const analytics = AnalyticsModule({
  app: appName,
  plugins: [
    customAnonIdPlugin,
    everflowSegmentPlugin(),
    {
      ...segmentPlugin,
      page: (params: { payload: { anonymousId: string; properties: { experiments?: unknown } } }) => {
        segmentPlugin.page(params)
      },
      track: (params: {
        payload: {
          event: string
          userId?: string
          anonymousId?: string
          properties: {
            experiments?: unknown
            funnel?: EcommPayload['funnel']
            projectSlug?: string
          }
        }
        config?: {
          disableAnonymousTraffic?: boolean
        }
      }) => {
        // node_modules/@analytics/segment/lib/analytics-plugin-segment.es.js:127 track()
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const client = window.analytics
        if (!client) {
          return segmentPlugin.track(params)
        }
        const payload = params.payload,
          config = params.config
        const userId = payload.userId,
          anonymousId = payload.anonymousId

        if (!userId && !anonymousId) {
          throw new Error('Missing userId and anonymousId. You must include one to make segment call')
        }

        const data = {
          event: payload.event,
          properties: payload.properties,
          ...(userId ? { userId } : { anonymousId }),
        }

        if (config?.disableAnonymousTraffic && !userId) {
          return false
        }

        client.track(
          data,
          !!params?.payload?.properties?.funnel &&
            ECOMMERCE_EVENTS.includes(params?.payload?.event as typeof ECOMMERCE_EVENTS[number])
            ? {
                integrations: {
                  'Facebook Pixel': false,
                },
              }
            : undefined,
        )

        const isSuppressedTheatricalFunnelProjectSlugEvent = // Suppress the theatrical funnel for the project slug
          params?.payload?.properties?.funnel === 'theatrical' &&
          params?.payload?.properties?.projectSlug &&
          SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(params?.payload?.properties?.projectSlug)

        if (
          !!params.payload.event &&
          !!params?.payload?.properties?.funnel &&
          ECOMMERCE_EVENTS.includes(params?.payload?.event as typeof ECOMMERCE_EVENTS[number]) &&
          !isSuppressedTheatricalFunnelProjectSlugEvent
        ) {
          client.track({ ...data, event: `${params.payload.event} - ${params.payload.properties.funnel}` })
        }
      },
      loaded: () => segmentPlugin?.loaded?.(),
    },
    {
      ...theatricalAngelPartytownPlugin,
      track: (params: {
        eventName: string
        payload: {
          event: string
          anonymousId: string
          properties: {
            experiments?: unknown
            funnel?: EcommPayload['funnel']
            projectSlug?: string
          }
        }
      }) => {
        const isSuppressedTheatricalFunnelProjectSlugEvent = // Suppress the theatrical funnel for the project slug
          params?.payload?.properties?.funnel === 'theatrical' &&
          params?.payload?.properties?.projectSlug &&
          SUPPRESSED_THEATRICAL_FUNNEL_PROJECT_SLUGS.includes(params?.payload?.properties?.projectSlug)

        if (isSuppressedTheatricalFunnelProjectSlugEvent) return false

        if (
          !params?.payload?.properties?.funnel ||
          (params?.payload?.properties?.funnel && !params.payload.properties.funnel.includes('theatrical'))
        ) {
          return false
        }

        theatricalAngelPartytownPlugin?.track?.({
          ...params,
          payload: {
            ...params.payload,
            event: `${params.payload.event} - ${params.payload.properties.funnel}`,
          },
        })
      },
    },
  ],
})

const growthbook = getGrowthbook({}, analytics.track)

export const Analytics: ReactFCC = ({ children }) => {
  const { user } = useUser()
  const router = useRouter()
  const [hasLoaded, setHasLoaded] = useState(false)
  const isAngelEmployeeUser = getIsAngelEmployeeUser(user?.email)

  const {
    context: {
      locale,
      os: { name: operatingSystem },
      referrer,
      sessionId,
      timezone,
      userAgent,
    },
    user: { userId, anonymousId },
  } = analytics.getState()

  useEffect(() => {
    growthbook.setAttributes({
      ...growthbook.getAttributes(),
      id: anonymousId,
      userId,
      isMobile,
      isDesktop,
      isAngelEmployeeUser,
      // TODO: what else??
    })
  }, [anonymousId, isAngelEmployeeUser, userId])

  const handleScreenChange = useCallback(
    (url: string, region?: string) => {
      const previous = analytics.getState().page?.last?.properties?.to
      analytics.page({
        from: previous || url,
        to: url,
        url,
        utm: getObjectFromSessionStorage('originalUtms', null),
        anonymous_id: anonymousId,
        region: region ? region : 'us',
        user_id: userId,
        session_id: sessionId,
        project: appName,
        buildId,
      })
    },
    [sessionId, anonymousId, userId],
  )

  const [branchFingerPrint] = useBranch()

  useEffect(() => {
    analytics.storage.setItem('browser_fingerprint_id', branchFingerPrint)
  }, [branchFingerPrint])

  const regionName = useMemo(() => {
    const regionString = router?.query?.region as string
    if (latamCountries.has(regionString)) return 'latam'
    if (!regionString) return 'us'
    return regionString
  }, [router?.query?.region])

  useEffect(() => {
    if (!hasLoaded && router) {
      const initialPageLoadUrl = router.asPath
      handleScreenChange(initialPageLoadUrl, regionName)
      setHasLoaded(true)

      if (isEmpty(getObjectFromSessionStorage('originalUtms')) && router?.query) {
        const originalUtms = {} as Record<string, string>
        for (const [key, value] of Object.entries(router.query)) {
          if (key.startsWith('utm_') && !!value) {
            originalUtms[key] = getItemOrFirstEntry(value)
          }
        }
        writeToSessionStorage('originalUtms', originalUtms)
      }
    }
  }, [handleScreenChange, router, hasLoaded, regionName])

  useEffect(() => {
    if (typeof window === 'undefined') return
    if (shouldTrackFullStoryImpression(router)) {
      fullstoryInit({
        orgId: process.env.NEXT_PUBLIC_FULL_STORY_ORG_ID || '',
        devMode: process.env.NEXT_PUBLIC_ANGEL_ENV !== 'production',
      })
    }
  }, [router])

  const hasVPPAConsent = useHasVPPAConsent()

  // eslint-disable-next-line sonarjs/cognitive-complexity
  useEffect(() => {
    const userId = user?.uuid
    const displayName = getUserDisplayName(user)
    const noVPPAConsent = 'no_vppa_consent'

    const fbc = getFbcCookie()
    const fbp = getFbpCookie()
    const fb_login_id = user?.socialAuth?.find((auth) => auth?.provider === 'facebook')?.socialUid || null
    const userTraits = {
      ...(analytics?.user?.()?.traits || {}),
      rid: getRID(),
      did: anonymousId,
      anonymousId,
      userId,
      email: hasVPPAConsent ? user?.email : noVPPAConsent,
      name: hasVPPAConsent ? displayName : noVPPAConsent,
      nickname: null, // no longer exists on api
      picture: hasVPPAConsent ? user?.profile?.image : noVPPAConsent,
      locale,
      operatingSystem,
      referrer,
      timezone,
      userAgent,
      isMobile,
      isAndroid,
      isIOS,
      region: regionName,
      ...(fbc ? { fbc } : {}),
      ...(fbp ? { fbp } : {}),
      ...(fb_login_id ? { fb_login_id } : {}),
    }

    if (userId) {
      analytics.identify(userId, userTraits)
      window?.branch?.setIdentity(userId)
      if (isFullstoryInitialized()) {
        const custom = hasVPPAConsent ? { displayName, email: user.email || '' } : undefined
        fullstoryIdentify(userId, custom)
      }
    } else {
      analytics.identify(analytics?.user()?.userId, userTraits)
    }
  }, [user, anonymousId, hasVPPAConsent, locale, operatingSystem, referrer, timezone, userAgent, regionName])

  const instance = useMemo(() => {
    return {
      ...analytics,
      track: (
        eventName: string,
        payload?: Record<string, unknown>,
        options?: Record<string, unknown>,
        callback?: (...params: unknown[]) => unknown,
      ) => {
        const { context, user } = analytics.getState()

        const fbc = getFbcCookie()
        const fbp = getFbpCookie()
        return analytics.track(
          eventName,
          {
            affiliation: 'Angel Web',
            user_id: user.userId,
            session_id: context.sessionId,
            anonymous_id: user.anonymousId,
            region: regionName,
            buildId,
            utm: getObjectFromSessionStorage('originalUtms', null),
            browser_fingerprint_id: analytics.storage.getItem('browser_fingerprint_id'),
            angel_env: process.env.NEXT_PUBLIC_ANGEL_ENV,
            ...(fbc ? { fbc } : {}),
            ...(fbp ? { fbp } : {}),
            ...(user?.traits?.fb_login_id ? { fb_login_id: user.traits?.fb_login_id } : {}),
            ...payload,
          },
          options,
          callback,
        )
      },
    }
  }, [regionName])

  return (
    <ExperimentContextProvider growthbook={growthbook}>
      <AnalyticsProvider instance={instance}>{children}</AnalyticsProvider>
    </ExperimentContextProvider>
  )
}

// facebook cookie
function getFbcCookie() {
  return getCookie('_fbc')
}
function getFbpCookie() {
  return getCookie('_fbp')
}

const FULL_STORY_SAMPLE_RATE = 0.3
function shouldTrackFullStoryImpression(router: NextRouter): boolean {
  if (router.asPath !== `${paths.guild.join}/angel-studios` && router.asPath !== paths.guild.join) return false

  // if this users session has already been tracked, don't track again
  if (getBooleanFromSessionStorage('fullStoryTracked')) return false

  if (Math.random() < FULL_STORY_SAMPLE_RATE) {
    writeToSessionStorage('fullStoryTracked', true)
    return true
  }

  return false
}
