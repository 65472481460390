import { FC } from 'react'
import { When } from 'react-if'
import LazyHydrate from 'react-lazy-hydration'
import { PaddedContainer } from '@/atoms/PaddedContainer'
import { MoviesAndSeriesCollection } from '@/molecules/MoviesAndSeriesCollection/MoviesAndSeriesCollection'
import { WatchNowCollection } from '@/organisms/WatchNowCollection'
import { TopPicksForYouCollection } from '@/organisms/WatchNowCollection/TopPicksForYouCollection'
import { ComingSoonCollection } from '@/page/ComingSoonCollection'
import { GuildEarlyAccessCollection } from '@/page/GuildEarlyAccessCollection'
import { GuildUpdatesPortraitRowCollection } from '@/page/GuildUpdatesPortraitRowCollection'
import { LivestreamLandscapeRowCollection } from '@/page/LivestreamLandscapeRowCollection'
import { NewOnAngelCollection } from '@/page/NewUpcomingCollection/NewOnAngelCollection'
import { UpcomingEpisodesSpecialsCollection } from '@/page/NewUpcomingCollection/UpcomingEpisodesSpecialsCollection'
import { SneakPeekCollection } from '@/page/SneakPeekCollection'
import { useGuildUser } from '@/services/GuildUserService'
import { PageDataContext } from '@/services/RenderService/types'
import { Collection as CollectionModel } from '@/types/codegen-contentful'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'

interface Props {
  collection: CollectionModel
  pageDataContext: PageDataContext
}

const renderSquareCollection = (pageDataContext: PageDataContext, isGuildMember: boolean) => (
  <PaddedContainer className="overflow-x-hidden" fluid>
    <LazyHydrate whenVisible>
      <>
        <When condition={isGuildMember}>{null}</When>
        <When condition={!isGuildMember}>
          <MoviesAndSeriesCollection contentLinks={pageDataContext['brand-logos']} />
        </When>
      </>
    </LazyHydrate>
  </PaddedContainer>
)

const renderPortraitGridCollection = (collection: CollectionModel, pageDataContext: PageDataContext) => (
  <ComingSoonCollection
    ComingSoonTabCollection={pageDataContext['coming-soon-tab'] ?? []}
    title={collection.title ?? ''}
    testId="coming-soon-collection"
    layout="portrait-grid"
  />
)

const renderLandscapeRowCollection = (
  collection: CollectionModel,
  pageDataContext: PageDataContext,
  isGuildMember: boolean,
  t: TranslateFunction,
) => {
  switch (collection.dataSource) {
    case 'start-watching':
      return (
        <>
          <When condition={isGuildMember}>
            <PaddedContainer className="mb-4 overflow-x-hidden" fluid>
              <TopPicksForYouCollection />
            </PaddedContainer>
            <PaddedContainer className="mb-12 overflow-x-hidden" fluid>
              <MoviesAndSeriesCollection contentLinks={pageDataContext['brand-logos']} />
            </PaddedContainer>
            <WatchNowCollection
              contentLinks={pageDataContext['start-watching'] ?? []}
              title={collection.title ?? ''}
              onlyShowContinue
            />
          </When>
          <When condition={!isGuildMember}>
            <WatchNowCollection
              contentLinks={pageDataContext['start-watching'] ?? []}
              title={t('startWatchingForFree', 'Start Watching for Free')}
              onlyShowFree
            />
          </When>
        </>
      )
    case 'continue-watching':
      return (
        <WatchNowCollection contentLinks={pageDataContext['start-watching'] ?? []} title={collection.title ?? ''} />
      )
    case 'early-access':
      return (
        <>
          <When condition={isGuildMember}>
            <PaddedContainer className="overflow-x-hidden" fluid>
              <GuildEarlyAccessCollection
                testId="early-access-collection"
                title={collection.title ?? ''}
                enableWatchProgress
              />
            </PaddedContainer>
          </When>
          <When condition={!isGuildMember}>
            <PaddedContainer className="overflow-x-hidden" fluid>
              <SneakPeekCollection
                sneakPeekTitleCollection={pageDataContext['sneak-peeks']}
                testId="sneak-peek-collection"
              />
            </PaddedContainer>
          </When>
        </>
      )
    case 'new-on-angel':
      return (
        <LazyHydrate whenVisible>
          <PaddedContainer className="overflow-x-hidden" fluid>
            <NewOnAngelCollection testId="new-on-angel-collection" title={collection.title ?? ''} enableWatchProgress />
          </PaddedContainer>
        </LazyHydrate>
      )
    case 'upcoming-episodes-specials':
      return (
        <LazyHydrate whenVisible>
          <PaddedContainer className="overflow-x-hidden" fluid>
            <UpcomingEpisodesSpecialsCollection
              testId="upcoming-episodes-specials-collection"
              title={collection.title ?? ''}
              ComingSoonTabCollection={pageDataContext['coming-soon-tab'] ?? []}
              enableWatchProgress
            />
          </PaddedContainer>
        </LazyHydrate>
      )
    case 'livestreams':
      return (
        <PaddedContainer className="overflow-x-hidden" fluid>
          <LivestreamLandscapeRowCollection testId="livestreams-collection" title={collection.title ?? ''} />
        </PaddedContainer>
      )
    default:
      return null
  }
}

const renderPortraitRowCollection = (collection: CollectionModel, pageDataContext: PageDataContext) => {
  switch (collection.dataSource) {
    case 'guild-updates':
      return (
        <GuildUpdatesPortraitRowCollection
          testId="livestreams-collection"
          title={collection.title ?? ''}
          paddingClassName="px-4 sm:px-8 md:px-12 xl:px-16"
        />
      )
    case 'coming-soon':
      return (
        <LazyHydrate whenVisible>
          <PaddedContainer className="overflow-x-hidden" fluid>
            <ComingSoonCollection
              ComingSoonTabCollection={pageDataContext['coming-soon-tab'] ?? []}
              title={collection.title ?? ''}
              testId="coming-soon-collection"
              layout="portrait-row"
            />
          </PaddedContainer>
        </LazyHydrate>
      )
    default:
      return null
  }
}

export const Collection: FC<Props> = ({ collection, pageDataContext }) => {
  const { isGuildMember } = useGuildUser()
  const { t } = useTranslate('watch')

  if (!collection?.dataSource) return null

  switch (collection.displayAs) {
    case 'square':
      return renderSquareCollection(pageDataContext, isGuildMember)
    case 'portrait-grid':
      return renderPortraitGridCollection(collection, pageDataContext)
    case 'landscape-row':
      return renderLandscapeRowCollection(collection, pageDataContext, isGuildMember, t)
    case 'portrait-row':
      return renderPortraitRowCollection(collection, pageDataContext)
    default:
      return null
  }
}
