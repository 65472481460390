import { gql as contentfulGQL } from '@apollo/client'
import { ContentfulClientOptions, getContentfulClient } from '@/services/ApolloClient'
import { Collection } from '@/types/codegen-contentful'

export const OUR_IMPACT_ENTRY_ID = '3Qfw4wtQcasJnK8An35iXI'
export const WATCH_FOR_FREE_ENTRY_ID = '5NPEcTQ5wrJvclQOiHVQFO'
export const SNEAK_PEEK_ENTRY_ID = '4k3ugMm8cJE8ZcI5IBptJh'
export const GUILD_MEMBERSHIP_NUMBERS_ID = '6jIe1S7pgjYmMceUTUTdS1'
export const SNEAK_PEEK_TO_PRIMARY_ENTRY_ID = 'Lv5pDgsKfa5JxKdOFxSlU'
export const HIDE_PROJECT_SLUGS_ENTRY_ID = '516X7XPtsdUN2Sl6yJ8tuh'

export const GET_COLLECTION_QUERY = contentfulGQL/* GraphQL */ `
  query getCollectionById($id: String!, $locale: String, $preview: Boolean) {
    collection(id: $id, locale: $locale, preview: $preview) {
      customData
      dataSource
      displayAs
      name
    }
  }
`

export async function getCollection(opts: ContentfulClientOptions, id: string): Promise<Collection> {
  const { data } = await getContentfulClient(opts).query({
    query: GET_COLLECTION_QUERY,
    variables: { id, locale: opts?.locale, preview: opts?.preview },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  })

  return data.collection
}
