import { gql as contentfulGQL, gql } from '@apollo/client'

export const getAllProjectsQuery = gql`
  query getAllProjects {
    projects {
      id
      projectType
      name
      pifEnabled
      slug
      logoCloudinaryPath
      discoveryPosterTransformation
      discoveryPosterCloudinaryPath
      seasons {
        id
        seasonNumber
        episodes {
          id
          slug
          episodeNumber
          guid
          subtitle
        }
      }
      videos {
        id
        guid
        page
        title
      }
    }
  }
`

export const GET_PROJECT_CONTENT_LINK_QUERY = gql`
  query getProjectContentLinks {
    projects {
      id
      discoveryPosterCloudinaryPath
      discoveryPosterTransformation
      logoCloudinaryPath
      pifEnabled
      name
      slug
      sortOrder
      public
      projectType
      primaryFlowPhases {
        status
        phaseSlugEnum
      }
      title {
        ... on ContentDisplayable {
          id
          landscapeTitleImage: image(aspect: "16:9", category: TITLE_ART) {
            aspect
            category
            cloudinaryPath
          }
          landscapeAngelImage: image(aspect: "16:9", category: ANGEL_KEY_ART_1) {
            aspect
            category
            cloudinaryPath
          }
          landscapeAngelImage2: image(aspect: "16:9", category: ANGEL_KEY_ART_2) {
            aspect
            category
            cloudinaryPath
          }
          landscapeAngelImage3: image(aspect: "16:9", category: ANGEL_KEY_ART_3) {
            aspect
            category
            cloudinaryPath
          }
          portraitTitleImage: image(aspect: "2:3", category: TITLE_ART) {
            aspect
            category
            cloudinaryPath
          }
          portraitAngelImage: image(aspect: "2:3", category: ANGEL_KEY_ART_1) {
            aspect
            category
            cloudinaryPath
          }
          portraitAngelImage2: image(aspect: "2:3", category: ANGEL_KEY_ART_2) {
            aspect
            category
            cloudinaryPath
          }
          portraitAngelImage3: image(aspect: "2:3", category: ANGEL_KEY_ART_3) {
            aspect
            category
            cloudinaryPath
          }
        }
      }
    }
  }
`

export const GET_OUR_SHOWS = gql`
  query getOurShows {
    listWatchables(first: 50, input: { filter: { type: { in: [SERIES, MOVIE] } } }) {
      edges {
        node {
          __typename
          ... on ContentSeries {
            id
            image(aspect: "2:3", category: TITLE_ART) {
              cloudinaryPath
            }
            project {
              id
              slug
              name
              highestScore
              releaseDate
            }
          }
          ... on ContentMovie {
            id
            image(aspect: "2:3", category: TITLE_ART) {
              cloudinaryPath
            }
            project {
              id
              slug
              name
              highestScore
              releaseDate
            }
          }
        }
      }
    }
  }
`

const EpisodeGuildEarlyAccessFragment = gql`
  fragment EpisodeGuildEarlyAccess on Episode {
    isAngelGuildOnly
    prereleaseAvailableFor
    isTrailer
    guildAvailableDate
    publiclyAvailableDate
    earlyAccessDate
    unavailableReason
  }
`

export const projectMetaFragment = gql`
  fragment ProjectMeta on Project {
    id
    releaseDate
    discoveryPosterLandscapeCloudinaryPath
    discoveryVideoLandscapeUrl
    franchiseDetails {
      watchSequence
      franchiseId
      slug
    }
    logoCloudinaryPath
    metadata {
      contentRating
      externalLinks
      genres
    }
    name
    primaryFlowPhases {
      status
      phaseSlugEnum
    }
    pifAvatar
    pifEnabled
    pifExplainerText
    pifExplainerVideoUrl
    projectType
    public
    seasons {
      id
      name
      seasonNumber
      episodes {
        id
        episodeNumber
        guid
        subtitle
        description
        releaseDate
        unavailableReason
        isTrailer
        prereleaseAvailableFor
      }
    }
    slug
    streamingAtLabel
    theaterDescription
    videos(page: "trailer") {
      id
      posterLandscapeCloudinaryPath
      source {
        duration
        url
      }
      subtitle
      title
    }
  }
`

export const getProjectMetaQuery = gql`
  ${projectMetaFragment}
  query getProjectMeta($slug: String!) {
    project(slug: $slug) {
      ...ProjectMeta
    }
  }
`

export const getProjectQuery = gql`
  ${EpisodeGuildEarlyAccessFragment}
  query getProject($slug: String!, $includePrerelease: Boolean = false, $includeSeasons: Boolean = true) {
    project(slug: $slug) {
      id
      releaseDate
      continueWatching @include(if: $includeSeasons) {
        id
        duration
        guid
        posterCloudinaryPath
        seasonId
        slug
        projectSlug
        watchPosition {
          id
          position
        }
      }
      primaryFlowPhases {
        status
        phaseSlugEnum
      }
      discoveryPosterLandscapeCloudinaryPath
      discoveryVideoLandscapeUrl
      logoCloudinaryPath
      name
      pifEnabled
      projectType
      seasons @include(if: $includeSeasons) {
        id
        episodes(includePrerelease: $includePrerelease, includePresale: $includePrerelease) {
          id
          currentUserHasEarlyAccess
          earlyAccessDate
          publiclyAvailableDate
          guildAvailableDate
          description
          episodeNumber
          guid
          id
          name
          posterCloudinaryPath
          posterLandscapeCloudinaryPath
          projectSlug
          seasonId
          seasonNumber
          source {
            credits
            duration
            url
          }
          slug
          subtitle
          unavailableReason
          upNext {
            id
            episodeNumber
            guid
            projectSlug
            seasonNumber
            subtitle
          }
          watchPosition {
            id
            position
          }
          ...EpisodeGuildEarlyAccess
        }
        id
        name
      }
      slug
      theaterDescription
      trailers {
        id
        name
        source {
          duration
          url
        }
      }
      franchiseDetails {
        watchSequence
        franchiseId
        slug
      }
    }
  }
`

export const GET_WATCH_POSITION_FOR_GUID = gql`
  query getWatchPositionForGuid($globalId: String!) {
    episodeByGlobalId(globalId: $globalId) {
      id
      source {
        duration
      }
      watchPosition {
        id
        position
        watchableGuid
      }
    }
  }
`

export const GET_EPISODE_BY_GUID_QUERY = gql`
  query getEpisodeByGuid($guid: ID!) {
    episode(guid: $guid) {
      id
      guid
      slug
      episodeNumber
      seasonNumber
      seasonId
      subtitle
      description
      name
      posterCloudinaryPath
      posterLandscapeCloudinaryPath
      projectSlug
      releaseDate
      source {
        credits
        duration
        url
      }
    }
  }
`

/**
 * This is intended for client side project, season, episode, etc. data where there is user data also being fetched with
 * authentication. We have been retrieving this on the backend through getServerSideProps for WatchEpisodeView
 * We should move to getStaticProps there and use the `getProjectAndEpisodeQuery` query without user data long term.
 */
export const GET_EPISODE_AND_USER_WATCH_DATA_QUERY = gql`
  ${EpisodeGuildEarlyAccessFragment}
  query getEpisodeAndUserWatchData(
    $guid: ID!
    $projectSlug: String!
    $includePrerelease: Boolean = false
    $authenticated: Boolean = false
    $reactionsRollupInterval: Int = 4000
  ) {
    project(slug: $projectSlug) {
      id
      projectType
      pifEnabled
      metadata {
        contentRating
        externalLinks
        genres
      }
      primaryFlowPhases {
        status
        phaseSlugEnum
      }
      discoveryPosterCloudinaryPath
      discoveryPosterLandscapeCloudinaryPath
      discoveryPosterTransformation
      discoveryVideoLandscapeUrl
      name
      slug
      seasons {
        id
        seasonNumber
        name
        episodes(includePrerelease: $includePrerelease, includePresale: $includePrerelease) {
          id
          guid
          slug
          episodeNumber
          seasonNumber
          seasonId
          subtitle
          description
          name
          posterCloudinaryPath
          posterLandscapeCloudinaryPath
          projectSlug
          earlyAccessDate
          publiclyAvailableDate
          guildAvailableDate
          releaseDate
          source {
            credits
            duration
            url
          }
          unavailableReason
          upNext {
            id
            projectSlug
            guid
            seasonNumber
            episodeNumber
            subtitle
          }
          watchPosition {
            position
          }
          introEndTime
          introStartTime
        }
      }
      logoCloudinaryPath
      publisher {
        name
      }
      trailers {
        id
        name
        source {
          duration
          url
        }
      }
      title {
        ... on ContentDisplayable {
          id
          landscapeTitleImage: image(aspect: "16:9", category: TITLE_ART) {
            aspect
            category
            cloudinaryPath
          }
          landscapeAngelImage: image(aspect: "16:9", category: ANGEL_KEY_ART_1) {
            aspect
            category
            cloudinaryPath
          }
          landscapeAngelImage2: image(aspect: "16:9", category: ANGEL_KEY_ART_2) {
            aspect
            category
            cloudinaryPath
          }
          landscapeAngelImage3: image(aspect: "16:9", category: ANGEL_KEY_ART_3) {
            aspect
            category
            cloudinaryPath
          }
          portraitTitleImage: image(aspect: "2:3", category: TITLE_ART) {
            aspect
            category
            cloudinaryPath
          }
          portraitAngelImage: image(aspect: "2:3", category: ANGEL_KEY_ART_1) {
            aspect
            category
            cloudinaryPath
          }
          portraitAngelImage2: image(aspect: "2:3", category: ANGEL_KEY_ART_2) {
            aspect
            category
            cloudinaryPath
          }
          portraitAngelImage3: image(aspect: "2:3", category: ANGEL_KEY_ART_3) {
            aspect
            category
            cloudinaryPath
          }
        }
      }
    }
    episode(guid: $guid) {
      description
      episodeNumber
      guid
      id
      name
      posterCloudinaryPath
      posterLandscapeCloudinaryPath
      projectSlug
      releaseDate
      seasonId
      seasonNumber
      slug
      subtitle
      source {
        credits
        duration
        url
      }
      unavailableReason
      upNext {
        id
        projectSlug
        guid
        seasonNumber
        episodeNumber
        subtitle
      }
      vmapUrl
      watchPosition {
        position
      }
      ...EpisodeGuildEarlyAccess
    }
    user @include(if: $authenticated) {
      id
      videoReactions(videoId: $guid, rollupInterval: $reactionsRollupInterval) {
        id
        momentId
        reactedAt
        videoGuid
        viewerId
      }
    }
  }
`

export const GET_START_WATCHING_QUERY = gql`
  query getStartWatching {
    projects {
      discoveryPosterLandscapeCloudinaryPath
      discoveryVideoUrl
      discoveryPosterTransformation
      discoveryPosterCloudinaryPath
      logoCloudinaryPath
      name
      projectType
      slug
      seasons {
        episodes {
          episodeNumber
          guid
          posterLandscapeCloudinaryPath
          seasonNumber
          slug
          subtitle
          userHasEarlyAccess
          isAngelGuildOnly
          prereleaseAvailableFor
          isTrailer
          guildAvailableDate
          publiclyAvailableDate
          earlyAccessDate
          unavailableReason
          name
        }
      }
    }
    viewer {
      region
    }
  }
`

/**
 * This is intended for server side project, season, episode, etc. data where there is NO user data also being fetched with
 * authentication. We have been retrieving this on the backend through getServerSideProps mainly for WatchEpisodeView.
 * We should move to getStaticProps there and use this query without user data long term and on the client use the
 * `GET_EPISODE_AND_USER_WATCH_DATA_QUERY` query
 */
export const getProjectAndEpisodeQuery = gql`
  ${EpisodeGuildEarlyAccessFragment}
  query getProjectAndEpisode($guid: ID!, $projectSlug: String!, $includePrerelease: Boolean = false) {
    project(slug: $projectSlug) {
      id
      projectType
      pifEnabled
      metadata {
        contentRating
        externalLinks
        genres
      }
      name
      slug
      seasons {
        id
        seasonNumber
        name
        episodes(includePrerelease: $includePrerelease, includePresale: $includePrerelease) {
          id
          guid
          slug
          episodeNumber
          seasonNumber
          seasonId
          subtitle
          description
          name
          posterCloudinaryPath
          posterLandscapeCloudinaryPath
          projectSlug
          activeDate
          prerelease
          prereleaseActiveDate
          releaseDate
          source {
            credits
            duration
          }
          upNext {
            projectSlug
            guid
            seasonNumber
            episodeNumber
            subtitle
          }
        }
      }
      logoCloudinaryPath
      publisher {
        name
      }
      videos(page: "trailer") {
        id
        posterLandscapeCloudinaryPath
        source {
          duration
          url
        }
        title
      }
    }
    episode(guid: $guid) {
      id
      guid
      slug
      episodeNumber
      seasonNumber
      seasonId
      subtitle
      description
      name
      posterCloudinaryPath
      posterLandscapeCloudinaryPath
      projectSlug
      releaseDate
      vmapUrl
      source {
        credits
        duration
      }
      upNext {
        id
        projectSlug
        guid
        seasonNumber
        episodeNumber
        subtitle
      }
      introEndTime
      introStartTime
      ...EpisodeGuildEarlyAccess
    }
  }
`
export const GET_MOMENT_ID_FROM_STORY = gql`
  query getMomentIdFromStory($userStoryId: ID!) {
    userStory(id: $userStoryId) {
      momentId
    }
  }
`

export const GET_AVAILABLE_COLLECTIBLE_OFFERS_BY_PROJECT = gql`
  query getAvailableCollectibleOffersByProject($input: AvailableCollectibleOffersInput!) {
    availableCollectibleOffers(input: $input) {
      id
      description
      name
      image
      projectId
      products {
        ... on CollectibleProductMoment {
          __typename
          id
          name
          description
          momentId
          price
          quantity
          image
          moment {
            id
            title
            type
            availableFrames
            reactionCount
            thumbnail
            startTime
            description
            episode {
              show {
                name
              }
              season {
                seasonNumber
              }
              episodeNumber
              description
            }
            defaultOffer {
              id
            }
          }
        }
        ... on CollectibleProductSignature {
          __typename
          id
          signerName
          signerUuid
          signerImage
          signerAssociation
          signerRole
          price
          quantity
          signerId
        }
        ... on CollectibleProductKeepsake {
          __typename
          shippingTier
          shippingDays
          shippingCost
          quantity
          price
          name
          id
          dimension
          description
          image
        }
        ... on CollectibleProductGuild {
          __typename
          id
        }
        ... on CollectibleProductPack {
          description
          id
          image
          images
          name
          price
          quantity
          soldOut
        }
      }
    }
  }
`

export const GET_BASIC_COLLECTIBLES_OFFERS_BY_PROJECT = gql`
  query availableCollectibleOffers($input: AvailableCollectibleOffersInput!) {
    availableCollectibleOffers(input: $input) {
      name
      image
      id
      description
      products {
        ... on CollectibleProductPack {
          id
          images
          image
        }
        ... on CollectibleProductKeepsake {
          id
        }
        ... on CollectibleProductSignature {
          id
          description
        }
        ... on CollectibleProductMoment {
          id
          description
          momentId
        }
      }
    }
  }
`

export const GET_AVAILABLE_MOMENT = gql`
  query getAvailableMoment($momentId: ID!) {
    moment(id: $momentId) {
      id
      title
      type
      reactionCount
      thumbnail
      episode {
        season {
          seasonNumber
        }
        show {
          name
        }
        episodeNumber
      }
      defaultOffer {
        description
        id
        name
        products {
          ... on CollectibleProductMoment {
            id
            name
            description
            momentId
            price
            quantity
          }
          ... on CollectibleProductSignature {
            id
            signerName
            signerUuid
            signerImage
            price
            quantity
            signerId
          }
          ... on CollectibleProductKeepsake {
            shippingTier
            shippingDays
            shippingCost
            quantity
            price
            name
            id
            dimension
            description
          }
          ... on CollectibleProductGuild {
            id
          }
        }
      }
    }
  }
`

export const GET_EPISODE_FROM_MOMENT = gql`
  query getEpisodeFromMoment($momentId: ID!) {
    moment(id: $momentId) {
      id
      title
      startTime
      thumbnail
      description
      type
      episode {
        id
        guid
        slug
        episodeNumber
        seasonNumber
        seasonId
        subtitle
        description
        name
        posterCloudinaryPath
        projectSlug
        releaseDate
        vmapUrl
        source {
          credits
          duration
          url
        }
      }
    }
  }
`

export const GET_FRAME_BY_ID = gql`
  query getFrameById($frameId: ID!) {
    frame(id: $frameId) {
      id
      uri
      frameNumber
      smpteTimecode
      episode {
        canonId
      }
      nifty {
        id
        ownedSince
        signatures {
          signatureUrl
          cloudinaryPath
        }
        gift {
          id
          createdAt
          message
          toFirstName
          toLastName
          gifter {
            uuid
            firstName
            lastName
          }
        }
      }
      user {
        uuid
        firstName
        lastName
        image
      }
    }
  }
`

export const GET_EPISODE_FROM_MOMENT_WITH_USER = gql`
  query getEpisodeFromMomentWithUser($momentId: ID!, $shareUserId: String!) {
    moment(id: $momentId) {
      id
      title
      type
      startTime
      thumbnail
      description
      owner(id: $shareUserId) {
        id
        profile {
          id
          image(fallbackToInitials: true)
          firstName
          lastName
        }
        story: userStoryByMoment(momentId: $momentId) {
          id
          text
          videoUri
          createdAt
          posterCloudinaryPath
        }
      }
      episode {
        id
        guid
        slug
        episodeNumber
        seasonNumber
        seasonId
        subtitle
        description
        name
        posterCloudinaryPath
        projectSlug
        releaseDate
        vmapUrl
        source {
          credits
          duration
          url
        }
      }
    }
    userStories(userStoriesInput: { where: { momentId: $momentId, userId: $shareUserId }, take: 1 }) {
      stories {
        id
        createdAt
        posterCloudinaryPath
        text
        videoUri
        user {
          id
          profile {
            id
            firstName
            lastName
            image(fallbackToInitials: true)
          }
        }
      }
    }
  }
`

export const GET_LIGHT_PROJECT_FOR_SHARE = gql`
  query getLightProjectForShare($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      name
      slug
      projectType
      pifEnabled
      primaryFlowPhases {
        status
        phaseSlugEnum
      }
    }
  }
`

export const getProjectAndEpisodeQueryForDeepLink = gql`
  query getProjectAndEpisodeQueryForDeepLink($guid: ID!, $projectSlug: String!, $includePrerelease: Boolean = false) {
    project(slug: $projectSlug) {
      id
      projectType
      discoveryPosterLandscapeCloudinaryPath
      name
      slug
      seasons {
        id
        episodes(includePrerelease: $includePrerelease, includePresale: $includePrerelease) {
          id
          guid
          projectSlug
        }
      }
    }
    episode(guid: $guid) {
      description
      episodeNumber
      seasonNumber
      subtitle
      projectSlug
    }
  }
`

export const getVideoSeoQuery = gql`
  query getVideoSeo($projectSlug: String!) {
    project(slug: $projectSlug) {
      id
      logoCloudinaryPath
      name
      projectType
      public
      trailers {
        id
        name
        source {
          url
        }
      }
      videos {
        id
        guid
        slug
        title
        subtitle
        posterLandscapeCloudinaryPath
        page
        source {
          credits
          duration
          url
        }
      }
    }
  }
`

export const getProjectAndFaqQuery = contentfulGQL/* GraphQL */ `
  query getProjectAndFaq($projectSlug: String!, $locale: String, $preview: Boolean) {
    projectCollection(limit: 1, where: { slug: $projectSlug }, locale: $locale, preview: $preview) {
      items {
        contentCatalogId
        distributionStage
        fundingPurpose
        currentlyFunding
        crowdfundingSlug
        mission
        showOverview
        hasHubPage
        showMetaDescription
        theaterBackgroundPosition
        pifConnectedCarouselCollection {
          items {
            variantKey
            title
            subtitle
            href
            imagesCollection {
              items {
                item
              }
            }
          }
        }
        defaultPifConnectedCarousel {
          variantKey
          title
          subtitle
          href
          imagesCollection {
            items {
              item
            }
          }
        }
        pifConnectedCarouselExperimentKey
        overrideTheaterWatchButtonBuyTicketsUrl
        overrideTheaterWatchButtonTrailerUrl
      }
    }
    faqCollection(
      where: { location: "watch", OR: [{ project: { slug: $projectSlug } }, { project: { slug: "angel-studios" } }] }
      locale: $locale
      preview: $preview
      order: ordinal_ASC
    ) {
      items {
        answer {
          json
        }
        ordinal
        question
      }
    }
    downloadableCollection(
      where: { OR: [{ location_exists: false }, { location: "browse" }], project: { slug: $projectSlug } }
      locale: $locale
      preview: $preview
      order: ordinal_ASC
    ) {
      items {
        href
        image
        title
        type
      }
    }
  }
`

export const getContentfulProjectConfig = contentfulGQL/* GraphQL */ `
  query getContentfulProjectConfig($projectSlug: String!, $locale: String) {
    projectCollection(limit: 1, where: { slug: $projectSlug }, locale: $locale) {
      items {
        fundingPurpose
        currentlyFunding
        crowdfundingSlug
        mission
        showOverview
        hasHubPage
        theaterBackgroundPosition
        pifPerkEarlyAccessMinimumPrice
        pifFeaturedOfferId
        pifEarlyAccessPerkId
        tributeOfferIDs
        pifFeaturedOfferText {
          json
        }
        shouldShowPifExplainerSection
        pifCustomInfoSection {
          json
        }
        pifFundingInfoModal {
          json
        }
        pifConnectedCarouselCollection {
          items {
            variantKey
            title
            subtitle
            href
            imagesCollection {
              items {
                item
              }
            }
          }
        }
        defaultPifConnectedCarousel {
          variantKey
          title
          subtitle
          href
          imagesCollection {
            items {
              item
            }
          }
        }
        pifConnectedCarouselExperimentKey
        shopifyStore {
          storeUrl
          accessToken
          handle
          storeHref
        }
      }
    }
  }
`

export const getProjectSitemapsQuery = gql`
  query getProjectSitemaps {
    projects {
      id
      livestreams {
        guid
        id
        endedAt
      }
      pifEnabled
      projectType
      public
      seasons {
        id
        seasonNumber
        episodes {
          id
          slug
          episodeNumber
          guid
          subtitle
        }
      }
      slug
      videos {
        id
        guid
        page
      }
    }
  }
`

export const getProjectDetailsQuery = gql`
  query getProjectDetails($slug: String!) {
    project(slug: $slug) {
      name
      pifEnabled
      projectType
      slug
    }
  }
`

const interestInitiativeFragment = gql`
  fragment InterestInitiative on InterestInitiativeType {
    guid
    minAmount
    maxAmount
    angelProjectSlug
    active
    contentfulSlug
  }
`

export const PROJECTS_IN_CURRENT_PHASE = gql`
  ${interestInitiativeFragment}
  query projectsInCurrentPhase($phaseSlug: PhaseSlug) {
    projectsInCurrentPhase(phaseSlug: $phaseSlug) {
      activeInterestInitiative {
        ...InterestInitiative
      }
      discoveryPosterCloudinaryPath
      discoveryPosterLandscapeCloudinaryPath
      discoveryPosterTransformation
      id
      logoCloudinaryPath
      name
      projectType
      public
      slug
      totalUniqueInterestUsers
    }
  }
`

export const GET_GUILD_ACCESS_PROJECTS = contentfulGQL/* GraphQL */ `
  query getGuildAccessProjects {
    projectCollection(where: { distributionStage: "early-access" }) {
      items {
        name
        slug
        showOverview
        filmDetails {
          moviePoster
        }
      }
    }
  }
`

export const GET_PROJECT_TILES = gql`
  query getProjectTiles {
    projects {
      discoveryPosterCloudinaryPath
      discoveryPosterTransformation
      id
      logoCloudinaryPath
      name
      projectType
      public
      slug
      sortOrder
    }
  }
`

export const GET_PROJECT_PUBLIC_INTEREST_INITIATIVES = gql`
  ${interestInitiativeFragment}
  query getProjectInterestInitiatives($slug: String) {
    project(slug: $slug) {
      name
      slug
      totalUniqueInterestUsers
      activeInterestInitiative {
        ...InterestInitiative
      }
      interestInitiatives {
        ...InterestInitiative
      }
    }
  }
`

export const getCurrentLivestreamQuery = gql`
  query getCurrentLivestream($projectId: ID!) {
    currentLivestream(projectId: $projectId) {
      guid
      name
      bannerCloudinaryPath
      live
      id
      startAt
      isEnded
      unavailableReason
      description
    }
  }
`

export const GET_PROJECT_GUILD_SCORE = gql`
  query fetchProjectGuildScore($projectSlug: String!) {
    projectV2BySlug(slug: $projectSlug) {
      id
      name
      highestScore
    }
  }
`

export const GET_WATCHABLE_EPISODE = gql`
  query getWatchableEpisode($guid: ID!) {
    getContent(id: $guid) {
      ... on ContentEpisode {
        id
        watchableAvailabilityStatus
        watchableReasons {
          __typename
        }
        watchPosition {
          position
        }
      }
    }
  }
`
