import { gql } from '@apollo/client'

export const GET_COMING_SOON_TAB = gql`
  query GetComingSoonTab($after: String, $preview: Boolean) {
    comingSoonTagGroup(slug: "all") {
      id
      slug
      title(version: SHORT)
      connection(first: 80, after: $after, preview: $preview) {
        edges {
          node {
            id
            header
            title
            subtitle
            focus {
              __typename
              type
              date
            }
            watchable {
              __typename
              ... on Node {
                id
              }
              ... on ContentPreviewable {
                preview {
                  id
                  m3u8Uri
                  duration
                }
              }
              ... on ContentEpisode {
                id
                subtitle
                episodeNumber
                season {
                  id
                  seasonNumber
                }
                project {
                  id
                  name
                }
                watchableAvailabilityStatus
              }
              ... on ContentSpecial {
                watchableAvailabilityStatus
              }
              ... on ContentWatchable {
                id
                duration
                watchPosition {
                  position
                }
              }
              ... on ContentDisplayable {
                name
                id
                description(version: SHORT)
                posterTitleImage: image(aspect: "2:3", category: TITLE_ART) {
                  aspect
                  category
                  cloudinaryPath
                }
                landscapeTitleImage: image(aspect: "16:9", category: TITLE_ART) {
                  aspect
                  category
                  cloudinaryPath
                }
                landscapeAngelImage: image(aspect: "16:9", category: ANGEL_KEY_ART_1) {
                  aspect
                  category
                  cloudinaryPath
                }
                landscapeStillImage: image(aspect: "16:9", category: STILL_1) {
                  aspect
                  category
                  cloudinaryPath
                }
                portraitTitleImage: image(aspect: "2:3", category: TITLE_ART) {
                  aspect
                  category
                  cloudinaryPath
                }
                portraitAngelImage: image(aspect: "2:3", category: ANGEL_KEY_ART_1) {
                  aspect
                  category
                  cloudinaryPath
                }
                portraitStillImage: image(aspect: "2:3", category: STILL_1) {
                  aspect
                  category
                  cloudinaryPath
                }
              }
            }
            project {
              id
              slug
              name
              public
              highestScore
              projectType
              primaryFlowPhases {
                phaseSlugEnum
                status
                releaseWindows {
                  start
                }
              }
            }
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
`
