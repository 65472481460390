import { projectTypes } from '@/constants/projectTypes'
import { slugs } from '@/constants/slugs'
import { ProjectMeta } from '@/services/ProjectsService'
import { TranslateFunction, useTranslate } from '@/utils/translate/translate-client'

const EXCLUDE_FROM_START_WATCHING = new Set([slugs.forYourConsideration, slugs.insideAngelStudios, slugs.theChosen])

const TRAILER_ONLY_PROJECT = new Set([slugs.cabrini])

// Some projects have "Episodes" that are actually teaser trailers, and we don't want those to show up in the Start Watching carousel
export const isProjectExcludedFromStartWatching = (slug: string): boolean => {
  return EXCLUDE_FROM_START_WATCHING.has(slug)
}

const IS_ANGEL_BEHIND_THE_SCENES = new Set([slugs.forYourConsideration, slugs.insideAngelStudios])

// These projects use the Season/Episode structure but they are actually behind the scenes videos, teasers and torches. We don't want to do things like show Attribution for these, and we want to build meta tags correctly as well
export const isAngelBehindTheScenes = (slug: string): boolean => {
  return IS_ANGEL_BEHIND_THE_SCENES.has(slug)
}

export const isTrailerOnlyProject = (slug: string): boolean => {
  return TRAILER_ONLY_PROJECT.has(slug)
}

const getDefaultTitle = (projectType: string, name: string, t: TranslateFunction): string => {
  return projectType === projectTypes.movie
    ? t('watchMovieOnlineForFree', `{{title}} | Watch Movie Online For Free | Angel Studios`, { title: name })
    : t('watchShowOnlineForFree', `{{title}} | Watch Online For Free | Angel Studios`, { title: name })
}

const getEarlyAccessTitle = (name: string, t: TranslateFunction): string => {
  return t('earlyAccessStreamingTitle', `{{title}} | Now Streaming for Angel Guild Members`, { title: name })
}

const getInTheatersTitle = (name: string, t: TranslateFunction, date?: string): string => {
  return t('inTheatersDateTitle', `{{title}} | Coming to Theaters {{date}}`, { title: name, date })
}

const getInTheatersSoonTitle = (name: string, t: TranslateFunction): string => {
  return t('inTheatersSoon', `{{title}} | Coming to Theaters Soon`, { title: name })
}

const getInTheatersNowTitle = (name: string, t: TranslateFunction): string => {
  return t('inTheatersNow', '{{title}} | In Theaters Now', { title: name })
}

const slugTitleMap: {
  [key: string]: { titleFunction: (name: string, t: TranslateFunction) => string }
} = {
  [slugs.homestead]: { titleFunction: getInTheatersNowTitle },
  [slugs.braveTheDark]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'Jan 24th 2025') },
  [slugs.ruleBreakers]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'March 2025') },
  [slugs.kingOfKings]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'Apr 11th 2025') },
  [slugs.theLastRodeo]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'May 23rd 2025') },
  [slugs.truthAndConviction]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'Oct 24th 2025') },
  [slugs.truthAndTreason]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'Oct 24th 2025') },
  [slugs.bethlehem]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'Holidays 2025') },
  [slugs.fellowship]: { titleFunction: (name, t) => getInTheatersTitle(name, t, '2026') },
  [slugs.youngWashington]: { titleFunction: (name, t) => getInTheatersTitle(name, t, 'July 4th 2026') },
  [slugs.tempest]: { titleFunction: getInTheatersSoonTitle },
  [slugs.jacob]: { titleFunction: getInTheatersSoonTitle },
  [slugs.insideAngelStudios]: { titleFunction: (name: string) => name },
}

export const useProjectMetaTitle = ({ name, projectType, slug }: ProjectMeta) => {
  const { t } = useTranslate('watch')

  let title = getDefaultTitle(projectType, name, t)

  if (slugTitleMap[slug]) {
    title = slugTitleMap[slug].titleFunction(name, t)
  } else if (
    [
      slugs.afterDeath,
      slugs.bonhoeffer,
      slugs.cabrini,
      slugs.forKingAndCountryADrummerBoyChristmasLive,
      slugs.homesteadFamilySurvival,
      slugs.homesteadSeries,
      slugs.lostOnAMountainInMaine,
      slugs.possumTrot,
      slugs.redemptionOfHenryMyers,
      slugs.riotAndTheDanceSeries,
      slugs.sight,
      slugs.somebodysGottaDoIt,
      slugs.somethingToStandForWithMikeRowe,
      slugs.soundOfFreedom,
      slugs.soundOfHopeTheStoryOfPossumTrot,
      slugs.surprisedByOxford,
      slugs.sweetwater,
      slugs.theBlind,
      slugs.theLunaticFarmer,
      slugs.theShift,
      slugs.theWay,
      slugs.twentyThreeBlast,
    ].includes(slug)
  ) {
    title = getEarlyAccessTitle(name, t)
  }

  return { title }
}

export const getIsTheatrical = (slug: string) => {
  return slug === slugs.soundOfFreedom
}
