import { gql } from '@apollo/client'
import { gql as contentfulGQL } from '@apollo/client'

export const GET_FRANCHISE_PAGE_COLLECTION = contentfulGQL/* GraphQL */ `
  query getFranchisePageCollection($slug: String!, $locale: String, $preview: Boolean!) {
    franchisePageCollection(where: { slug: $slug }, locale: $locale, preview: $preview, limit: 1) {
      items {
        slug
        heroBackgroundVideo
        shopifyStore {
          storeUrl
          accessToken
          handle
          storeHref
        }
        posterHref
        posterDesktop {
          altText
          item
        }
        posterTablet {
          altText
          item
        }
        posterMobile {
          altText
          item
        }
        projectsCollection(limit: 5) {
          items {
            slug
            franchiseWatchOrderText
          }
        }
        logoOverride {
          item
        }
      }
    }
  }
`

export const GET_FRANCHISE_BY_SLUG = gql`
  query getFranchiseBySlug($slug: String!, $includePrerelease: Boolean = true) {
    franchiseBySlug(slug: $slug) {
      id
      name
      slug
      description(version: MEDIUM)
      shortDescription: description(version: SHORT)
      images(category: LOGO, aspect: "original") {
        cloudinaryPath
      }
      backdropLandscape: images(category: BACKDROP, aspect: "16:9") {
        cloudinaryPath
      }
      backdropPortrait: images(category: BACKDROP, aspect: "9:16") {
        cloudinaryPath
      }
      projects {
        name
        slug
        theaterDescription
        projectType
        highestScore
        logoCloudinaryPath
        metadata {
          genres
          contentRating
        }
        seasons {
          name
          episodes(includePrerelease: $includePrerelease, includePresale: $includePrerelease) {
            name
            description
            posterLandscapeCloudinaryPath
            posterCloudinaryPath
            earlyAccessDate
            publiclyAvailableDate
            activeDate
            unavailableReason
            slug
            guid
            subtitle
            watchPosition {
              position
            }
            source {
              duration
            }
            subtitle
            isTrailer
            seasonNumber
            projectSlug
          }
        }
        franchiseDetails {
          watchSequence
        }
        primaryFlowPhases {
          status
          phaseSlugEnum
          releaseWindows {
            start
            end
            startSpecificity
          }
        }
        title {
          ... on ContentDisplayable {
            id
            landscapeTitleImage: image(category: TITLE_ART, aspect: "16:9") {
              cloudinaryPath
            }
            landscapeAngelImage: image(category: ANGEL_KEY_ART_1, aspect: "16:9") {
              cloudinaryPath
            }
            landscapeAngelImage3: image(category: ANGEL_KEY_ART_3, aspect: "16:9") {
              cloudinaryPath
            }
            landscapeBackdropImage: image(category: BACKDROP, aspect: "16:9") {
              cloudinaryPath
            }
            portraitTitleImage: image(category: TITLE_ART, aspect: "2:3") {
              cloudinaryPath
            }
            portraitAngelImage: image(category: ANGEL_KEY_ART_1, aspect: "2:3") {
              cloudinaryPath
            }
            portraitAngelImage3: image(category: ANGEL_KEY_ART_3, aspect: "2:3") {
              cloudinaryPath
            }
            portraitBackdropImage: image(category: BACKDROP, aspect: "2:3") {
              cloudinaryPath
            }
          }
        }
      }
    }
  }
`

export const GET_LIGHT_FRANCHISE_BY_SLUG = gql`
  query getLightFranchiseBySlug($slug: String!) {
    franchiseBySlug(slug: $slug) {
      id
      name
      slug
      description(version: MEDIUM)
      images(category: ANGEL_KEY_ART_1, aspect: "16:9") {
        cloudinaryPath
      }
      projects {
        id
        name
      }
    }
  }
`
